<template>
  <div class="create-scheduling">
    <CForm
      ref="createMember"
      novalidate
      class="create-form"
    >
      <div class="form-content content">
        <h3 class="mb-3 bread-content">
          {{ texts.createPage.schedulingStep.title }}
          <a
            v-if="model === 'rewardTypes'"
            href="#"
            target="_blank"
            class="link ml-2"
          >
            {{ texts.createPage.schedulingStep.schedulingGuidanceLink }}
          </a>
        </h3>
        <ZqAwardScheduling
          v-model="formData"
          :label="'Scheduling'"
          :tooltip="'Scheduling tooltip'"
          :disabled="false"
          :hidden="false"
          :placeholder="'placeholder'"
          @input="updateScheduling"
        />
      </div>
    </CForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { range } from 'lodash';
import DatePicker from '@/shared/components/DatePicker';
import SwitchRow from '@/shared/components/SwitchRow';
import { isAchFieldDisabled } from '@/utils/validationUtils';
import ClFormRow from '@/shared/components/formComponents/ClFormRow';
import ClSelect from '@/shared/components/formComponents/ClSelect';
import { formConfig } from '@/config';
import { achievements } from '@/config/descriptions/achievements';
import { awardsTexts } from '@/config/pageTexts/awards.json';
import { rewardTypesTexts } from '@/config/pageTexts/rewardTypes.json';
import ZqAwardScheduling from '@/shared/components/ZqAwardScheduling.vue';

export default {
  name: 'CreateScheduling',
  components: {
    ZqAwardScheduling,
    DatePicker,
    ClFormRow,
    ClSelect,
    SwitchRow,
  },
  props: {
    schedulingData: Array,
    name: String,
    isFrom: Boolean,
    isScheduleType: Boolean,
    isLimit: Boolean,
    achievementType: String,
    isMessagingPage: { type: Boolean, default: false },
    model: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      texts: this.model === 'rewardTypes' ? { ...rewardTypesTexts } : { ...awardsTexts },
      endDateErrorMessage: achievements.create.scheduling.endDateErrorMessage,
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      startDate: '',
      endDate: null,
      formData: [],
      fromValidate: null,
      descriptions: {
        achievementAvailableFrom: achievements.list.create.scheduling.achievementAvailableFrom,
        till: achievements.list.create.scheduling.till,
        membersCanAchieveOn: achievements.list.create.scheduling.membersCanAchieveOn
      },
      invalidEndDate: false
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    isFieldDisabled() {
      return isAchFieldDisabled(this.achievementType);
    },
    selectErrorClass() {
      const className = this.theme === 'main' ? 'error-validation--multiselect' : 'error-validation';

      return {
        [className]: this.everyValidate
      };
    }
  },
  methods: {
    initialize() {
      this.formData = this.schedulingData;
    },
    updateScheduling(data) {
      this.formData = data;
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        this.$emit('updateSchedulingData', val);
      },
    },
  },
  destroyed() {
    this.$emit('resetValidate');
  }
};
</script>

<style lang="scss">
@import "~@coreui/coreui/scss/coreui";

.create-scheduling {
  height: 100%;

  .create-form {
    height: 100%;
  }

  .form-content {
    background: var(--zq-sub-bg);
    height: 100%;

    .link {
      color: #007dfb;
      cursor: pointer;
      text-decoration: underline;
    }

    .link:hover {
      color: #1387fb;
    }

    .zq--data-picker.mx-datepicker {
      width: 100%;
      min-width: 100%;
    }

    .zq--form-row {
      .zq--form-row--content {
        position: relative;

        .zq--data-picker--wrap.end-date.zq-invalid {
          border: solid 1px var(--zq-warn) !important;
          border-radius: 12px;
        }

        .invalid-end-date {
          position: absolute;
          bottom: -17px;
          left: 10px;
          color: var(--zq-warn);
        }
      }
    }
  }

  .every {
    display: flex;
    flex-wrap: wrap;

    .every-date-el {
      background: #f1f1f1 none repeat scroll 0 0;
      border: 1px solid #f1f1f1;
      cursor: pointer;
      float: left;
      font-size: 16px;
      line-height: 30px;
      margin: 0 2px 2px 0;
      min-width: 45px;
      padding: 0 3px;
      text-align: center;
    }

    .active {
      background: #e4e3e3 none repeat scroll 0 0;
      border: 1px solid #aaaaaa;
      position: relative;

      &:after {
        content: "+";
        font-size: 11px;
        line-height: 7px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .zq-invalid {
    .invalid-feedback {
      display: block;
    }
  }
}
</style>
